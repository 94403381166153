import { useContext } from 'react'
import { ColorModeContext } from '../../AppTheme'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'

export const ToggleDarkMode = () => {
	const colorMode = useContext(ColorModeContext)

	return (
		<FormGroup>
			<FormControlLabel
					label='Dark mode'
					control={
						<Checkbox
							checked={colorMode.mode === 'dark'}
							onClick={colorMode.toggle}
						/>
					}
				/>
		</FormGroup>
	)
}
