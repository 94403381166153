import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material'

export const InstallInstructions = ({ isOpen, onClose }) => {
	return (
		<Dialog
			open={isOpen}
			onClose={onClose}>
			<DialogTitle>Ope!</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{`Your browser requires that you install Wanna to enable push notifications. Look in the browser menus for "Install" or "Add to home screen". Then try again!`}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Ok</Button>
			</DialogActions>
		</Dialog>
	)
}
