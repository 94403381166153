import { useQuery } from '@apollo/client'
import Mood from '@mui/icons-material/Mood'
import {
	AppBar,
	Avatar,
	Chip,
	Dialog,
	DialogTitle,
	IconButton,
	Stack,
	Toolbar,
} from '@mui/material'
import { useState } from 'react'
import { ShareButton } from '../../components/ShareButton'
import { ToolbarTitle } from '../../components/ToolbarTitle'
import { UserSelector } from '../../components/UserSelector'
import GET_FRIENDS from '../../gql/getFriends.gql'
import { useFriendUser } from '../../hooks/useFriendUser'
import PersonAdd from '@mui/icons-material/PersonAdd'
import { REFERRER_URL_PARAM } from '../../utils/constants'
import { Blurb } from '../../components/Blurb'
import { GoBackButton } from '../../components/GoBackButton'
import { LazyUserGraph } from '../../components/LazyUserGraph'

export const Friends = () => {
	const [searchOpen, setSearchOpen] = useState(false)
	const { data: { me } = {} } = useQuery(GET_FRIENDS)
	const friends = me?.connections.filter((user) =>
		user.connectedBy.some(
			(connection) => connection.__typename === 'Friendship'
		)
	)
	const { addFriend, removeFriend } = useFriendUser()

	return (
		<Stack
			height='100%'
			minHeight={0}>
			<AppBar position='static'>
				<Toolbar>
					<GoBackButton />
					<ToolbarTitle>friends</ToolbarTitle>
					<IconButton
						onClick={() => setSearchOpen(true)}
						color='inherit'>
						<PersonAdd />
					</IconButton>
					<ShareButton
						text='Come join me on Wanna!'
						link={`${window.location.origin}?${REFERRER_URL_PARAM}=${me?.id}`}
					/>
				</Toolbar>
			</AppBar>
			{friends?.length === 0 && (
				<Blurb
					icon={<Mood sx={{ width: '64px', height: '64px' }} />}
					text='try searching for users or sharing an invite link from the top right'
				/>
			)}
			<LazyUserGraph
				users={(friends ?? []).concat(me ? [me] : [])}
				height='100%'
				myChip={
					me && (
						<Chip
							label='Me'
							color='secondary'
							avatar={
								<Avatar
									alt={me.name}
									src={me.pic}
								/>
							}
						/>
					)
				}
			/>
			<Dialog
				open={searchOpen}
				onClose={() => setSearchOpen(false)}>
				<DialogTitle sx={{ pb: 0 }}>friends</DialogTitle>
				<UserSelector
					initialChip='search'
					limitSearchToConnections={false}
					selectedUserIds={friends?.map((user) => user.id)}
					onSelected={(userIds) => {
						userIds.forEach((userId) => {
							addFriend(userId)
						})
					}}
					onDeselected={(userIds) => {
						userIds.forEach((userId) => {
							removeFriend(userId)
						})
					}}
				/>
			</Dialog>
		</Stack>
	)
}
