import TurnLeft from '@mui/icons-material/TurnLeft'
import { Box, Button, Fab, List, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { Blurb } from '../../components/Blurb'
import { ShareButton } from '../../components/ShareButton'
import { WannaItem } from '../../components/WannaItem'
import { useConnectedWannas } from '../../hooks/useConnectedWannas'
import { useMe } from '../../hooks/useMe'
import { REFERRER_URL_PARAM } from '../../utils/constants'
import { cmpWannas } from '../../utils/utils'
import { HomeToolbar } from '../nav/HomeToolbar'
import { IWanna } from './IWanna'
import { MyStatus } from '../status/MyStatus'

export const Wannas = () => {
	const me = useMe()
	const [isCreateOpen, setCreateOpen] = useState(false)
	const navigate = useNavigate()
	const wannas = useConnectedWannas()

	return (
		<Stack height='100%'>
			<HomeToolbar />
			{wannas?.length ? (
				<List
					disablePadding
					sx={{ flex: 1, overflow: 'auto' }}>
					{wannas
						?.slice()
						?.sort(cmpWannas)
						?.map((wanna) => (
							<WannaItem
								key={wanna.id}
								wanna={wanna}
								onClick={() => navigate(`/app/wannas/${wanna.id}`)}
							/>
						))}
				</List>
			) : (
				<Box
					height='100%'
					position='relative'>
					<Stack
						direction='row'
						position='absolute'
						top={4}
						right={33}>
						<Typography color='text.secondary'>
							find friends
							<br />
							and circles
						</Typography>
						<TurnLeft
							sx={{ transform: 'rotate(90deg)', color: 'text.secondary' }}
						/>
					</Stack>
					<Blurb
						text={"it's quiet in here... too quiet.\nwhy don't you:"}
						cta={
							<Stack
								alignItems='stretch'
								gap={1}>
								<Button
									variant='contained'
									color='secondary'
									onClick={() => setCreateOpen(true)}>
									take the initiative
								</Button>
								<ShareButton
									bigButtonText='invite friends'
									text='Come join me on Wanna!'
									link={`${window.location.origin}?${REFERRER_URL_PARAM}=${me?.id}`}
									sx={{ width: '100%' }}
								/>
							</Stack>
						}
					/>
				</Box>
			)}
			<Box position='relative'>
				<MyStatus />
				<Fab
					sx={{
						right: 20,
						top: -60,
						position: 'absolute',
					}}
					variant='extended'
					onClick={() => setCreateOpen(true)}
					color='secondary'>
					<Typography
						color='white'
						textTransform='none'
						fontWeight='bold'>
						I wanna...
					</Typography>
				</Fab>
			</Box>
			{/* TODO: breaks the close animation but too lazy rn to properly reset the dates */}
			{isCreateOpen && (
				<IWanna
					isOpen={isCreateOpen}
					onClose={() => setCreateOpen(false)}
				/>
			)}
		</Stack>
	)
}
