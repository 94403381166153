import Send from '@mui/icons-material/Send'
import { IconButton, Stack, TextField, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useStatus } from '../../hooks/useStatus'
import { useSend } from '../../hooks/useSend'
import { usePageVisibility } from 'react-page-visibility'
import { useParamaterizedCleanup } from '../../hooks/useParamaterizedCleanup'
import { OPTIMISTIC_ID } from '../../utils/constants'
import { LoginButton } from '../../components/LoginButton.jsx'
import { useMe } from '../../hooks/useMe'

export const Input = ({ wannaId, myParticipantShip }) => {
	const me = useMe()
	const [input, setInput] = useState('')
	const updateStatus = useStatus(wannaId)
	const send = useSend(wannaId)
	const isVisible = usePageVisibility()

	useEffect(() => {
		if (!myParticipantShip || myParticipantShip.id == OPTIMISTIC_ID) return

		const newStatus = isVisible
			? input.length > 0
				? 'typing'
				: 'present'
			: null
		if (newStatus !== myParticipantShip.status) updateStatus(newStatus)
		if (newStatus === 'typing') {
			const timeout = setTimeout(() => {
				updateStatus('present')
			}, 3000)
			return () => clearTimeout(timeout)
		}
	}, [input.length, isVisible, myParticipantShip?.id])

	useParamaterizedCleanup(() => {
		if (myParticipantShip) {
			updateStatus(null)
		}
	}, [!!myParticipantShip])

	const handleSendClicked = useCallback(() => {
		send(input)
		setInput('')
	}, [input, setInput, send])

	return (
		<Stack
			direction='column'
			gap={1}>
			{!me && (
				<Stack
					direction='row'
					alignItems='center'
					gap={1}>
					<LoginButton />
					<Typography>to join the fun!</Typography>
				</Stack>
			)}
			<Stack
				direction='row'
				gap={1}>
				<TextField
					disabled={!me}
					size='small'
					autoComplete='off'
					placeholder='Type a message'
					multiline
					// Compensate for Android keyboard covering messages by just scrolling to the bottom.
					// Probably more ideal to maintain scroll position, via adapting
					// https://stackoverflow.com/questions/59587515/how-to-make-the-mobile-keyboard-appearance-bump-the-div-rather-than-absolutely-p
					// but I'm lazy rn.
					// if (navigator.userAgent.toLowerCase().includes('android')) {
					// onFocus={() => scrollToEnd(true)}
					// onClick={() => scrollToEnd(true)}
					value={input}
					sx={{ width: '100%' }}
					// TODO typing stops any active scrolling
					onChange={(e) =>
						setInput(e.target.value.replace(/\n/g, '').slice(0, 1024))
					}
					onKeyPress={(e) => {
						if (e.key === 'Enter' && input.trim() !== '') {
							handleSendClicked()
						}
					}}
				/>
				<IconButton
					// Don't steal focus from text field
					onMouseDown={(e) => e.preventDefault()}
					onPointerDown={(e) => e.preventDefault()}
					onClick={handleSendClicked}
					disabled={!me || input.trim() === ''}>
					<Send color={input.trim() === '' ? 'inherit' : 'primary'} />
				</IconButton>
			</Stack>
		</Stack>
	)
}
