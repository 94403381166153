import { useQuery } from '@apollo/client'
import Add from '@mui/icons-material/Add'
import SelectAllIcon from '@mui/icons-material/SelectAll'
import DeselectIcon from '@mui/icons-material/Deselect'
import Groups from '@mui/icons-material/Groups'
import Mood from '@mui/icons-material/Mood'
import Search from '@mui/icons-material/Search'
import {
	Badge,
	Button,
	Checkbox,
	Chip,
	CircularProgress,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	Stack,
	TextField,
	Typography,
} from '@mui/material'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import SEARCH_USERS from '../gql/searchUsers.gql'
import { NavAvatar } from './NavAvatar'
import { useMe } from '../hooks/useMe'
import { useMyConnections } from '../hooks/useMyConnections'

// I kinda wonder if this would work better with ok/cancel buttons,
// and a callback at the end. So the parent component doesn't have
// to manage the intermediate selected state.
export const UserSelector = ({
	initialChip,
	selectedUserIds,
	bonusUsers,
	onSelected,
	onDeselected,
	limitSearchToConnections = true,
}) => {
	const navigate = useNavigate()
	const [query, setQuery] = useState('')
	const me = useMe()
	const myConnections = useMyConnections()
	const [selectedChipId, setSelectedChipId] = useState(initialChip ?? 'all')

	const { data: { searchUsers } = {}, loading } = useQuery(SEARCH_USERS, {
		variables: {
			query: query,
		},
		skip: query.length < 3,
	})

	// NOTE: excludes circles that have no users
	const availableCircles = myConnections
		?.flatMap((user) => user.connectedBy)
		.filter((connection) => connection.__typename === 'Circle')
		.filter(
			(value, index, self) =>
				self.map((circle) => circle.id).indexOf(value.id) === index
		)
	const myFriends = myConnections?.filter((user) =>
		user.connectedBy.some(
			(connection) => connection.__typename === 'Friendship'
		)
	)
	const availableChips = useMemo(() => {
		return [
			{ id: 'search', name: null },
			{ id: 'all', name: 'all' },
			{ id: 'friends', name: 'friends' },
		].concat(availableCircles ?? [])
	}, [availableCircles])

	const usersForChip = (chipId) => {
		let users
		if (chipId === 'search')
			users = searchUsers?.filter(
				(searchedUser) =>
					!limitSearchToConnections ||
					myConnections?.some((user) => user.id === searchedUser.id)
			)
		else if (chipId === 'all') users = allUsers
		else if (chipId === 'friends') users = myFriends
		else
			users = myConnections?.filter((user) =>
				user.connectedBy.some(
					(connection) =>
						connection.__typename === 'Circle' && connection.id === chipId
				)
			)
		users = users?.filter((user) => user.id !== me?.id)?.sort(cmpUsers)
		return users
	}

	const countForChip = (chipId) =>
		usersForChip(chipId)?.filter((user) => selectedUserIds.includes(user.id))
			.length ?? 0

	const allUsers = useMemo(() => {
		if (!myConnections) return null
		return myConnections
			.concat(bonusUsers ?? [])
			.concat(usersForChip('search') ?? []) // TODO: won't display searched users anymore if they change the query, but it'll still be selected
			.filter(
				(value, index, self) =>
					self.map((user) => user.id).indexOf(value.id) === index
			)
			.filter((user) => user.id !== me?.id)
	}, [myConnections, me?.id])

	const usersForSelectedChip = usersForChip(selectedChipId)
	const isCurrentListFullySelected =
		selectedUserIds.filter((id) =>
			usersForSelectedChip?.map((user) => user.id).includes(id)
		).length === usersForSelectedChip?.length

	const handleSelectAll = () => {
		const listedUserIds = usersForSelectedChip.map((user) => user.id)
		if (isCurrentListFullySelected) {
			onDeselected(listedUserIds)
		} else {
			const newSelected = listedUserIds.filter(
				(userId) => !selectedUserIds.includes(userId)
			)
			onSelected(newSelected)
		}
	}

	return (
		<Stack
			height='100%'
			alignSelf='stretch'
			minHeight={0}>
			<Stack
				direction='row'
				width='100%'
				pr={0}
				alignItems='center'>
				<Stack
					direction='row'
					gap={0.5}
					px={1.5}
					// margin/padding is a hack to fix https://stackoverflow.com/questions/6421966/css-overflow-x-visible-and-overflow-y-hidden-causing-scrollbar-issue
					sx={{
						pt: 2,
						mt: -2,
						overflowX: 'scroll',
						overflowY: 'visible',
						'::-webkit-scrollbar': {
							display: 'none',
						},
						scrollbarWidth: 'none',
					}}>
					{availableChips.map((c) => (
						<Badge
							key={c.id}
							badgeContent={countForChip(c.id)}
							color='primary'>
							<Chip
								label={c.name}
								variant={selectedChipId === c.id ? 'filled' : 'outlined'}
								color={selectedChipId === c.id ? 'secondary' : 'default'}
								sx={c.name ? {} : iconOnlySx}
								icon={
									c.id === 'all' ? (
										<Groups />
									) : c.id === 'friends' ? (
										<Mood />
									) : c.id === 'search' ? (
										<Search />
									) : null
								}
								onClick={() => setSelectedChipId(c.id)}
							/>
						</Badge>
					))}
					<Chip
						variant='outlined'
						icon={<Add />}
						onClick={() => navigate('/app/circles')}
						sx={iconOnlySx}
					/>
				</Stack>
				{!(isCurrentListFullySelected && onDeselected === undefined) && ( // Don't show deselect all button when it wouldn't do anything
					<IconButton
						sx={{ px: 2 }}
						onClick={handleSelectAll}>
						{isCurrentListFullySelected ? <DeselectIcon /> : <SelectAllIcon />}
					</IconButton>
				)}
			</Stack>
			{selectedChipId === 'search' && (
				<Stack pt={2}>
					<TextField
						sx={{ mx: 2 }}
						size='small'
						variant='outlined'
						placeholder='search for users'
						value={query}
						onChange={(e) => setQuery(e.target.value)}
						autoComplete='off'
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<CircularProgress
											size={20}
											sx={{ visibility: loading ? 'visible' : 'hidden' }}
										/>
								</InputAdornment>
							),
						}}
					/>
				</Stack>
			)}
			{allUsers?.length === 0 ? (
				<NoConnectionsCta />
			) : (
				<List sx={{ flex: 1, overflow: 'auto', alignSelf: 'stretch' }}>
					{usersForSelectedChip?.map((user) => (
						<ListUser
							key={user.id}
							user={user}
							onSelected={onSelected}
							onDeselected={onDeselected}
							isSelected={selectedUserIds.includes(user.id)}
						/>
					))}
				</List>
			)}
		</Stack>
	)
}

const NoConnectionsCta = () => {
	const navigate = useNavigate()
	return (
		<Stack
			justifyContent='center'
			alignItems='center'
			flex={1}>
			<Stack
				direction='column'
				justifyContent='center'
				width='fit-content'
				p={4}
				spacing={1}>
				<Typography
					variant='body'
					textAlign='center'>
					people you know on Wanna will appear here
				</Typography>
				<Button
					variant='contained'
					onClick={() => navigate('/app/people')}>
					add friends
				</Button>
				<Button
					variant='contained'
					onClick={() => navigate('/app/circles')}>
					join circles
				</Button>
			</Stack>
		</Stack>
	)
}

const ListUser = ({ user, isSelected, onSelected, onDeselected }) => {
	const handleToggle = () => {
		if (isSelected) onDeselected?.([user.id])
		else onSelected?.([user.id])
	}
	return (
		<ListItem
			key={user.id}
			dense
			secondaryAction={
				<Checkbox
					edge='end'
					color='secondary'
					checked={isSelected}
					onChange={handleToggle}
				/>
			}>
			<ListItemButton
				disableGutters
				onClick={handleToggle}>
				<ListItemAvatar>
					<NavAvatar user={user} />
				</ListItemAvatar>
				<ListItemText
					primary={user.name}
					secondary={
						user.status ? '● ' + user.status.toLocaleLowerCase() : null
					}
					secondaryTypographyProps={{
						color: 'green',
						fontWeight: 'bold',
					}}
				/>
			</ListItemButton>
		</ListItem>
	)
}

function cmpUsers(a, b) {
	return (
		(b.status === 'FLEXIBLE') - (a.status === 'FLEXIBLE') ||
		a.name.localeCompare(b.name)
	)
}

const iconOnlySx = {
	'.MuiChip-label': {
		width: 0,
		p: 0,
	},
	'.MuiChip-icon': {
		p: 0,
		m: 1,
	},
}
