import { useMutation } from '@apollo/client'
import UPDATE_MY_STATUS from '../gql/updateMyStatus.gql'
import { useMe } from './useMe'

export const useMyStatus = () => {
	const me = useMe()
	const [updateMyStatus] = useMutation(UPDATE_MY_STATUS)

	return [
		me?.status,
		(status) =>
			{
				return updateMyStatus({
					variables: { status },
					optimisticResponse: {
						__typename: 'Mutation',
						updateMyStatus: {
							__typename: 'User',
							id: me?.id,
							status,
						},
					},
				})
			},
	]
}
