import { Stack, Typography } from '@mui/material'
import { LargeSwitch } from '../../components/LargeSwitch'
import { useMyStatus } from '../../hooks/useMyStatus'

export const MyStatus = () => {
	const [myStatus, setMyStatus] = useMyStatus()

	return (
		<Stack
			p={1}
			gap={1}
			boxShadow={3}
			alignItems='center'
			justifyContent='center'
			direction='row'>
			<Typography
				color='textSecondary'
				variant='caption'
				textAlign='end'
				flexBasis='33%'>
				let friends know you&apos;re flexible
			</Typography>
			<LargeSwitch
				checked={myStatus === 'FLEXIBLE'}
				onChange={(e) => {
					if (e.target.checked) {
						setMyStatus('FLEXIBLE')
					} else {
						setMyStatus(null)
					}
				}}
			/>
			<Typography
				flexBasis='33%'
				color='textSecondary'
				variant='caption'>
				lasts 3 hours
			</Typography>
		</Stack>
	)
}
